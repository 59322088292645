import {
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  ServerParseError,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import i18n from 'i18next';

import { ApplicationError, EErrorCodeClient, EErrorCodeOperator, EErrorKind } from '@phoenix7dev/common-errors';

import { EventTypes } from '../global.d';
import { eventManager } from '../slotMachine/config';
import { isBaseGameMode, isBuyFeatureMode } from '../utils';
import { fallBackReelPosition } from '../utils/fallback';

import {
  setFreeRoundBonus,
  setGameMode,
  setIsFreeRoundBonus,
  setIsRevokeThrowingError,
  setReplayBet,
  setSlotConfig,
  setStressful,
} from './cache';
import { isStoppedGql } from './query';
import typePolicies from './typePolices';

type ErrorTypes =
  | 'errors.CLIENT.INSUFFICIENT_FUNDS'
  | 'errors.CLIENT.INVALID_CLIENT_TOKEN'
  | 'errors.ADAPTER.INVALID_GAME_TOKEN'
  | 'errors.ADAPTER.INVALID_LAUNCH_TOKEN'
  | 'errors.UNKNOWN.UNKNOWN'
  | 'errors.UNKNOWN.NETWORK';
const REST_URL = process.env['REACT_APP_URL'] as string;
const { NETWORK_RETRY_ATTEMPTS = 5, NETWORK_RETRY_DELAY = 1000 } = window.__ENV__;
const ERROR_CODES = [503, 502];
const RETRY_OPERATIONS = ['PlaceBet'];

const CSRF_HEADER = 'x-csrf-token';
let csrfToken = '';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const { retryCount } = operation.getContext();
  const statusCode = (networkError as ServerParseError)?.statusCode;
  if (RETRY_OPERATIONS.includes(operation.operationName) && ERROR_CODES.includes(statusCode)) {
    if (typeof retryCount === 'undefined' || retryCount < NETWORK_RETRY_ATTEMPTS) {
      forward(operation);
      return;
    }
  }

  if (setIsRevokeThrowingError()) return;
  if (graphQLErrors) {
    setIsRevokeThrowingError(true);
    if (isBuyFeatureMode(setGameMode()) || isBaseGameMode(setGameMode())) {
      fallBackReelPosition();
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const { message, extensions } = err;
      setIsRevokeThrowingError(true);
      const e = ApplicationError.getShapeByAppCode(extensions?.['applicationCode'] as number);
      if (e.kind === EErrorKind.CLIENT) {
        if (e.code === EErrorCodeClient.INSUFFICIENT_FUNDS) {
          setStressful({
            show: true,
            type: 'balance',
            message: i18n.t([extensions?.['i18nKey'] as string, 'errors.UNKNOWN.UNKNOWN']) || message,
          });
          return;
        }
      }
      if (e.kind === EErrorKind.OPERATOR) {
        if (e.code === EErrorCodeOperator.INVALID_BONUS && setIsFreeRoundBonus()) {
          setStressful({
            show: true,
            type: 'network',
            message: i18n.t([extensions?.['i18nKey'] as string, 'errors.UNKNOWN.UNKNOWN'] as ErrorTypes[]) || message,
            callback: () => {
              setFreeRoundBonus({
                ...setFreeRoundBonus(),
                isActive: false,
              });
              setStressful({ show: false, type: 'none', message: '' });
              eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END, true);
            },
          });
          return;
        }
      }

      setStressful({
        show: true,
        type: 'network',
        message:
          i18n.t([
            (extensions && (extensions['i18nKey'] as string)) || 'errors.UNKNOWN.UNKNOWN',
            'errors.UNKNOWN.UNKNOWN',
          ]) || message,
      });
    }
  } else if (networkError) {
    setIsRevokeThrowingError(true);
    if (isBuyFeatureMode(setGameMode()) || isBaseGameMode(setGameMode())) {
      fallBackReelPosition();
    }
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.NETWORK'),
    });
  } else {
    setIsRevokeThrowingError(true);
    if (isBuyFeatureMode(setGameMode()) || isBaseGameMode(setGameMode())) {
      fallBackReelPosition();
    }
    setStressful({
      show: true,
      type: 'network',
      message: i18n.t('errors.UNKNOWN.UNKNOWN'),
    });
  }
});

const connectionParams = (operationName: string | undefined) => {
  const { sessionId } = setSlotConfig();
  if (
    setReplayBet() &&
    (operationName === 'replayBet' ||
      operationName === 'replaySlotHistory' ||
      operationName === 'getReplayUserBonuses' ||
      operationName === 'replayGetUser' ||
      operationName === 'replayBonusBets' ||
      operationName === 'uselessReplayBet' ||
      operationName === 'betsByInitialRoundId')
  ) {
    return {
      'x-replay-token': 'af01adc0-071d-4a62-a191-abd62fc98e05',
      'x-bet-id': setReplayBet(),
    };
  }

  return {
    Authorization: sessionId,
  };
};

const authLink = setContext((gqlRequest: GraphQLRequest) => {
  return {
    headers: {
      ...connectionParams(gqlRequest.operationName),
    },
  };
});

const httpLink = new HttpLink({
  uri: REST_URL,
});

const retryLink = new RetryLink({
  delay: {
    initial: NETWORK_RETRY_DELAY,
    max: NETWORK_RETRY_DELAY,
    jitter: true,
  },
  attempts: (count, operation, error) => {
    // eslint-disable-next-line
    const status = error?.networkError?.statusCode || error?.statusCode;
    const { operationName } = operation;
    if (count <= NETWORK_RETRY_ATTEMPTS) {
      operation.setContext((context: Record<string, unknown>) => ({ ...context, retryCount: count }));
      return RETRY_OPERATIONS.includes(operationName) && ERROR_CODES.includes(status);
    }
    return false;
  },
});

const readSecurityLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const headers = (context['response'] as { headers: Headers })?.headers;

    if (headers.get(CSRF_HEADER)) {
      csrfToken = headers.get(CSRF_HEADER) as string;
    }

    return response;
  });
});

const writeSecurityLink = new ApolloLink((operation, forward) => {
  if (csrfToken) {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        [CSRF_HEADER]: csrfToken,
      },
    }));
  }
  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies,
});

cache.writeQuery({
  query: isStoppedGql,
  data: {
    isSlotStopped: true,
  },
});

const client = new ApolloClient({
  link: authLink.concat(from([readSecurityLink, writeSecurityLink, retryLink, errorLink, httpLink])),
  cache,
});

export default client;
