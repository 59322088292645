import { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';
export const titleTextStyle: Partial<ITextStyle> = {
  fontSize: 80,
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  fill: ['0xD0D0FF', '0x8080FF'],
  stroke: '#ffffff',
  strokeThickness: 4,
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 12,
};
export const descriptionTextStyle: Partial<ITextStyle> = {
  fontSize: 75,
  fontFamily: font,
  whiteSpace: 'normal',
  fill: '0xFFFFFF',
  align: 'center',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 12,
};
export const btnTextStyle: Partial<ITextStyle> = {
  fontSize: 100,
  fontFamily: font,
  fill: '0xFFFFFF',
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 15,
};
export const subtitleTextStyle: Partial<ITextStyle> = {
  fontSize: 130,
  fontFamily: font,
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  stroke: '#ffffff',
  strokeThickness: 8,
  align: 'center',
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 20,
};
export const freeSpinsTitleLevelStyles: Partial<ITextStyle> = {
  fontSize: 80,
  fontFamily: font,
  fill: ['0xD0D0FF', '0x8080FF'],
  stroke: '#ffffff',
  strokeThickness: 4,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
};
export const freeSpinsRetriggerDescriptionStyles: Partial<ITextStyle> = {
  fontFamily: font,
  fontSize: 50,
  fill: '0xFFFFFF',
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'pre-line',
  lineJoin: 'round',
  padding: 8,
};
export const freeSpinsTotalWinTitleStyles: Partial<ITextStyle> = {
  fontSize: 95,
  fontFamily: font,
  fill: ['0xD0D0FF', '0x8080FF'],
  stroke: '#ffffff',
  strokeThickness: 4,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
  padding: 15,
};
export const winTotalSpinsTextStyles: Partial<ITextStyle> = {
  fontSize: 120,
  fontFamily: font,
  fill: ['#FBC41C', '#F5FF3C', '#FFFFFF', '#FF8737', '#F9FF11', '#FBC41C', '#f08418', '#9f6518', '#ffeb19'],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  stroke: '#ffffff',
  strokeThickness: 8,
  align: 'center',
  fontWeight: 'bolder',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  dropShadow: true,
  dropShadowAlpha: 0.48,
  dropShadowBlur: 7.4,
  dropShadowColor: '#18484f',
  lineJoin: 'round',
};
export const freeSpinsTotalSpinsStyles: Partial<ITextStyle> = {
  fontSize: 60,
  fontFamily: font,
  fill: ['0xD0DFFF', '0x00DFFF'],
  stroke: '#ffffff',
  strokeThickness: 4,
  fontWeight: 'bolder',
  align: 'center',
  whiteSpace: 'normal',
  fontVariant: 'normal',
  lineJoin: 'round',
};
